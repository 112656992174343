@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.page-container {
    background-color: #282c34;
    min-height: 100vh;
    padding: 1em;
    color:  black;
}

button.sign-up {
    position: absolute;
    font-family: 'Press Start 2P', cursive;
    font-size: 12px;
    background-color: #FDCD47;
    color: white;
    padding: 1em;
    top: 2em;
    right: 2em;
}

.signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-container {
    color: white;
    background-image: url("../../assets/landing-page-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #09223d;
}

.login-header-img {
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: center;
    background-image: url("../../assets/landing-page-asc-name.png");
}