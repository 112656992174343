/* App.css */

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}

.landing-card {
  background: rgb(254,189,11);
  background: linear-gradient(90deg, rgba(254,189,11,1) 0%, rgba(254,189,11,1) 8%, rgba(255,237,189,1) 22%, rgba(217,201,157,1) 85%, rgba(207,166,54,1) 95%);
  border: solid 1px #6b6b6b;
  border-radius: 50px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.button-group {
  margin-top: 20px;
  margin-left: 30px;
}

.logo {
  float: right;
  width: 50px;
}
h2 {
  margin-top: 2rem;
}

button {
 border: 1px solid #46c6ea;
 border-radius: 5px;
 background: #46c6ea;
 color: black;
 padding: 5px;
 padding-right: 14px;
 padding-left: 12px;
 font-size: 17px;
 font-weight: bold;
 cursor: pointer;
}

button:hover {
 background-color: #7ce2ff;
 color: black;
 border: 1px solid  #7ce2ff;
}

/* form-input.css */

.group {
 position: relative;
 margin: 25px 0;
}

.form-input-label {
 position: absolute;
 left: 5px;
 top: 0px;
 color: #e8e8e8;
 pointer-events: none;
 transform: translateY(1rem);
 transition: 150ms cubic-bezier(0.4,0,0.2,1);
}

input, textarea {
 border: solid 1.5px #9e9e9e;
 border-radius: 7px;
 background: none;
 padding: 1rem;
 font-size: 1rem;
 color: #f5f5f5;
 transition: border 150ms cubic-bezier(0.4,0,0.2,1);
 margin: 1em;
}

/* input, textarea {
    min-width: 100%;
    margin: 1em;
    padding: 1em;
    border: none;
    border-radius: 10px;
}

button {
    cursor: pointer;
    margin: 1em;
    padding: 1em;
    border: none;
    min-width: 95%;
}

button:hover {
    filter: brightness(80%);
} */


/* label {
    margin: 1em;
    font-size: 20px;
} */

input:focus, input:valid, textarea:focus, textarea:valid {
 outline: none;
 border: 1.5px solid #46c6ea;
}

input:focus ~ .form-input-label, input:valid ~ .form-input-label {
 transform: translateY(-50%) scale(0.8);
 background-color: #282c34;
 padding: 0 .2em;
 color: #46c6ea;
}

#login {
  cursor: pointer;
}