/* App.css */

.page-container {
  background-image: url("../../assets/adventure-view-background2.png");
  /* max-height: 100vh; */
  padding-top: 150px;
  display: flex;
  align-items: top;
  justify-content: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  height: fit-content;
}

.scrollable-container {
  display: flex;
  flex-direction: column;
  width:100%;
  height:90vh;
  overflow:auto;
}

.page {
    min-width: 75%;
  }