.container {
    color: white;
    background-image: url("../../assets/challenges-view-background2.png");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #103b40;
    min-height: 100vh;
}

.quest-sub-container {
    background: rgba(255,250,250, .9);
    border-radius: 20px;
    padding: 10px;
    padding-top: 0;
    color: black;
}

.challenge-header {
    /* color: blueviolet */
    color: black;
}

.challenges-container {
    /* background: rgba(255, 122, 89, .5); */
    background: rgba(255, 255, 255, 0.6);
    /* opacity: .5; */
    padding-left: 15%;
    color: whitesmoke;
    border-radius: 20px;
    padding-top: 10px;
    padding-right: 15%;
}

.profile-content {
    padding-top: 150px;
    margin: 2em;
}

.profile-title {
    margin-left: 3em;
}

.profile-items {
    width: 100%;
}

.quest-container {
    border-radius: 20px;
    width: 60%;
    color: black;
    background-color: white;
}

.quest-n-progress {
    display: flex;
    gap: 4em;
}

.sign-out {
    align-self: center;
    width: 90vw;
    padding: 1em;
    margin: 1em;
}