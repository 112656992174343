.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

  }

  .close {
    position: absolute;
    top: 2em;
    right: 2em;
    font-size: 14px;
    font-family: 'Press Start 2P', cursive;
    color: white;
    cursor: pointer;
  }

  span.error-msg {
    color: rgba(211, 42, 42, 0.892);
    flex-shrink: inherit;
    font-size: 15px;
}
